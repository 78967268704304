.navbar-container-div {
  position: fixed;
  z-index: 1;
  background-color: #043b5d;
  width: 100px;
  height: 100vh;
  overflow: auto;
  /* Add this */
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.6);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 3fr 1fr;
}

.nav-buttons-container-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px 0;
  gap: 50px;
  grid-row: 2 / 3;
}

.nav-button,
.nav-button-active {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: filter 0.5s;
}

.nav-button-active {
  filter: brightness(100);
}

.nav-button:hover {
  filter: brightness(100);
  transition: filter 2s ease;
}

.nav-button label,
.nav-button-active label {
  pointer-events: none;
  color: var(--main-font-color);
  font-family: var(--main-font-family);
  font-weight: 600;
  font-size: 12px;
}

.for-german.navbar-container-div {
  width: unset;
  min-width: 100px;
  padding: 0px 10px;
}



/* MOBILE STYLING */

.welcomeTextMobile {
  color: var(--primary-text-color);
  font-family: var(--main-font-family);
  font-size: 16px;
  font-weight: 600;
}

.mobile-navbar-container-div {
  position: fixed;
  background-color: #043b5d;
  width: 200px;
  height: 100vh;
  overflow: auto;
  /* Add this */
  z-index: 2;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.6);
}

.mobile-nav-buttons-container-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px 0;
  gap: 50px;
}

.mobile-navbar-container-div.open {
  transform: translateX(0);
}

.mobileNavIcon {
  position: fixed;
  display: flex;
  align-items: center;
  height: 100px;
  margin-left: 20px;
  z-index: 3;
}

.burger-icon {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.burger-icon span {
  background-image: linear-gradient(1800deg, #e9c567, #c39334);
  height: 3px;
  width: 24px;
  display: block;
  transition: all 0.3s ease;
}

.burger-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-icon.open span:nth-child(2) {
  opacity: 0;
}

.burger-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

.mobile-nav-buttons-container-div img.mobileUserIcon {
  display: inline;
}

/* Media Queries for Responsive Gaps */

@media (max-width: 900px) {
  .depositCashYellowButton {
    font-size: 12px;
  }

  .dropdownButton {
    font-size: 12px;
  }

  .loggedOutYellowButton {
    font-size: 12px;
  }

  .buyTokenBlueButton {
    font-size: 12px;
  }
}

@media (max-height: 950px) {
  .mobile-nav-buttons-container-div {
    padding-top: 30px;
    gap: 30px;
  }
}

@media (max-height: 900px) {
  .mobile-nav-buttons-container-div {
    padding-top: 30px;
    gap: 35px;
  }

  .nav-buttons-container-div {
    gap: 35px;
    padding-top: 30px;
  }

  .mobile-nav-buttons-container-div img {
    display: none;
  }
}

@media (max-height: 720px) {
  .mobile-nav-buttons-container-div {
    padding-top: 70px;
    gap: 25px;
  }

  .nav-buttons-container-div {
    gap: 25px;
    padding-top: 30px;
  }
}

@media (max-height: 630px) {
  .nav-buttons-container-div {
    gap: 25px;
    padding-top: 80px;
  }

  .mobile-nav-buttons-container-div {
    padding-top: 100px;
    gap: 25px;
  }

  .mobile-nav-buttons-container-div img {
    display: none;
  }
}

@media (max-height: 590px) {
  .nav-buttons-container-div {
    gap: 25px;
    padding-top: 80px;
  }

  .mobile-nav-buttons-container-div {
    padding-top: 180px;
    gap: 25px;
  }

  .mobile-nav-buttons-container-div img {
    display: none;
  }
}

@media (max-height: 500px) {
  .nav-buttons-container-div {
    gap: 25px;
    padding-top: 80px;
  }

  .mobile-nav-buttons-container-div {
    padding-top: 300px;
    gap: 25px;
  }

  .mobile-nav-buttons-container-div img {
    display: none;
  }
}