.poker .coming-soon h1 {
    font-size: 40px;
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
    text-align: center;
}

.poker .coming-soon {
    padding-top: 8vh;
}

/* Disables banner cards, remove this styling when sports page is ready to use */
.poker-banner-cards .banner-card-container {
    transition: unset;
    cursor: not-allowed;
    filter: grayscale(1);
}

.poker-banner-cards .banner-card-container:hover {
    transform: unset;
    text-shadow: unset;
    box-shadow: unset;
}

/* Disables search bar, remove this styling when sports page is ready to use */
.poker-subheader .search-button,
.poker-subheader .search-bar {
    display: none;
}

/* Disables category buttons, remove this styling when sports page is ready to use */
.poker-categories .category-section .category-button-container {
    background: unset;
    cursor: not-allowed;
    filter: grayscale(1);
}

.poker-categories .category-section .category-button-container::before {
    transition: none;
    opacity: unset;
}

.poker-categories .category-section .category-button-container:hover {
    box-shadow: unset;
}

.poker-categories .category-section .category-button-container:hover span {
    font-weight: 300;
}

.poker-categories .category-button {
    cursor: not-allowed !important;
    filter: grayscale(1);
}