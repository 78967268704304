.localization-selector-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  z-index: 5;
}

.localization-selector-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 25px;
  width: 80vw;
  max-width: 500px;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: auto;
  /* Ensure scrollability if content overflows */
}

.localization-selector-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #08236e;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%;
}

.localization-selector-header h1 {
  margin: 0;
  font-family: var(--main-font-family);
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  flex-grow: 1;
}

.localization-selector-header button {
  position: absolute;
  right: 20px;
  display: flex;
  cursor: pointer;
  padding-right: 10px;
  color: #fff;
  background-color: transparent;
  border: none;
  font-size: 20px;
}

.localization-selector-languages-container-div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 20px 0;
}

.localization-selector-languages-container-div button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 80px;
  background-color: lightgrey;
  color: black;
  border: none;
  border-radius: 10px;
  font-family: var(--main-font-family);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.localization-selector-languages-container-div button img {
  width: 28px;
  height: 20px;
}

.localization-selector-languages-container-div button:hover {
  background-color: var(--main-font-color);
  color: white;
}

.language-code {
  font-size: 12px;
  font-weight: 400;
}

.language-name {
  font-size: 18px;
  font-weight: 600;
}

.language-close {
  width: 16px;
  height: 16px;

}

.language-close:hover {
  transform: scale(1.05);
}

.selected-language {
  background-color: var(--main-font-color);
  /* Highlight color */
  color: white;
}

/* Media Queries for Width below 400px */
@media (max-width: 400px) {
  .localization-selector-languages-container-div button {
    width: 100%;
    /* Make each button take full width */
    height: 60px;
    /* Reduce button height */
  }

  .localization-selector-content {
    width: 90vw;
    /* Adjust container width */
    padding: 0px;
  }

  .localization-selector-header h1 {
    font-size: 14px;
  }

  .language-code {
    font-size: 10px;
  }

  .language-name {
    font-size: 14px;
  }
}

/* Media Queries for Height below 840px */
@media (max-height: 840px) {
  .localization-selector-languages-container-div button {
    width: 45%;
    /* Adjust button width to fit more in one row */
    height: 60px;
    /* Reduce button height */
  }
}