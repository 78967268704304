.popup-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    padding: 20px;
    z-index: 5;
}

.mobile-popup-form {
    padding: 20px;
}

.mobile-page-1 {
    background-color: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.mobile-page-2 {
    background-color: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.mobile-page-2-disclaimer {
    background-color: #fff;
    border-radius: 25px;
}

.popup-mobile h2 {
    margin-bottom: 20px;
}

.mobile-button-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    width: 100%;
}

.mobile-bank-option-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 20px;
    width: 100%;
}

.popup-mobile .disclaimer-visible {
    width: 100%;
    height: 110px;
    padding: 0 20px;
}

.popup-header h1 {
    margin: 0 15px;
    font-family: var(--main-font-family);
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    padding-bottom: 10px;
}

.popup-header h1.active-section {
    border-bottom: 2px solid white;
}

.popup-header h1:hover {
    border-bottom: 2px solid var(--main-font-color);
    transition: opacity 0.3s ease;
}

.submit-request-button {
    width: 300px !important;
    font-size: 16px !important;
}

.mobile-token-balance-container-div {
    text-align: left;
    margin: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.mobile-token-balance-container-div h3,
.mobile-token-balance-container-div span {
    color: var(--main-font-color);
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 20px;
}

.mobile-token-balance-container-div h3 {
    width: 60%;
    line-height: 1;
}

.mobile-token-balance-container-div span {
    width: auto;
}

.mobile-withdrawal-page-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.mobile-withdrawal-page-form-item {
    width: 100%;
    display: flex;
    align-items: center;
}

.mobile-withdrawal-page-form label {
    font-family: var(--main-font-family);
    font-size: 13px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: left;
    width: 30%;
}

.mobile-withdrawal-page-form input {
    border: 1px solid black;
    font-family: var(--main-font-family);
    font-weight: 400;
    font-size: 13px;
    width: 700px;
    height: 41px;
    background-color: transparent;
    margin-left: auto;
    padding: 0 16px;
}

.mobile-withdrawal-page-form input::-webkit-outer-spin-button,
.mobile-withdrawal-page-form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.mobile-withdrawal-page-form input[type="number"] {
    -moz-appearance: textfield;
}

.mobile-withdrawal-page-form-item span {
    font-size: 0.8em;
    position: absolute;
    width: max-content;
    left: 0;
    bottom: -22px;
}

.mobile-withdrawal-page-form-item:nth-child(2) label {
    position: relative;
}

@media only screen and (max-width: 458px) {
    .mobile-page-2-disclaimer {
        background-color: #fff;
        border-radius: 25px;
        height: 600 px;
    }
}

.text-center{
    text-align: center;
}

.wallet-connect-button{
    background-color: #00e8a3;
    padding: 10px 10px;
    border: #00e8a3;
    font-weight: 600;
    border-radius: 8px;
}

.wallet-connect-icon{
    width: 18px;
    margin-right: 8px;
}

.wallet-connect-icon-2{
    width: 18px;
    margin-left: 8px;
}

.wallet-connected-icon{
    width: 12px;
    margin-left: 2px;
    margin-top: -8px;
}

.walletBalance{
    padding-top: 10px;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

  .form-wallet-connected{
    padding-top: 3px;
  }

  .walletConnectText{
    height: 70px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    font-size: 14px;
  }

  .etheriumAmount{
    float: right;
    font-size: 12px;
  }

  .etheriumIcon{
    width: 26px;
    margin-left: 2px;
  }

  .input-container-box {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .input-container-box-icon {
    position: absolute;
    right: 10px; /* Adjust this value to control the spacing from the right edge */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* This makes sure the icon doesn't block input interactions */
  }

  .popup-form-wallet-connected{
    padding: 20px 35px 0px 35px;
  }

  .popup-form-wallet-connected p {
    text-align: left;
}

.popup-form-wallet-connected h2 {
    text-align: left;
}

.mobile-page-3 {
    background-color: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}


.infoIconMobile{
    width: 50px;
    margin-top: -5px;
}

@media (max-width: 768px) {
    .tooltip-text {
        font-size: 14px;
        bottom: 120%; /* Adjust positioning for mobile */
        padding: 10px 15px;
        left: 50%; /* Center the tooltip */
        transform: translateX(-50%); /* Center the tooltip horizontally */
      }
      .grid-button-wallet-connect{
        width: 100% !important;
      }
}

.depositContainer{
    overflow: auto;
}