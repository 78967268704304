.bank-payment-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.bank-payment-popup {
    background: white;
    width: 80%;
    max-width: 600px;
    border-radius: 25px;
    height: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.bankPopupHeader {
    margin: 0;
    font-family: var(--main-font-family);
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    flex-grow: 1;
    padding-bottom: 10px;

}

.bank-popup-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background: #fff;
    padding: 20px;
    position: relative;
    text-align: center;
}

@media (max-width: 768px) {
    .bank-payment-popup {
        background: white;
        width: 90%;
        border-radius: 25px;
        height: 80%;
        position: relative;
        display: flex;
        flex-direction: column;
    }
}