.events .coming-soon h1 {
    font-size: 40px;
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
    text-align: center;
}

.events .coming-soon {
    padding-top: 8vh;
}

.events .events-page-sub-banner-card {
    transition: unset;
    cursor: not-allowed;
    filter: grayscale(1);
}

.events .events-page-sub-banner-card:hover {
    transform: unset;
    text-shadow: unset;
    box-shadow: unset;
}