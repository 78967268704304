.submit-request-button {
  border: none;
  background-image: linear-gradient(#f3b902, #946e16);
  /* background: #808080 !important; */
  box-shadow: 0 0 6px 1px grey !important;
  color: white;
  width: 450px;
  height: 45px;
  border-radius: 37.5px;
  font-family: var(--main-font-family);
  font-weight: 500;
  letter-spacing: 5px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  position: relative;
}

.submit-request-button:hover {
  background: linear-gradient(to bottom, #e2b729, #ac8920);
  box-shadow: 0 0 6px 1px gold;
  /* background: #808080 !important;
  box-shadow: 0 0 6px 1px grey !important;
  cursor: not-allowed */
}

.arrow {
  margin-left: 10px;
}

.loading-spinner {
  width: 32px;
  height: 32px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {

  .submit-request-button {
    margin-top: 0px;
  }
}