.userDropdown {
    position: relative;
    display: inline-block;
}

.userDropdownButton {
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.userIcon {
    width: 50px;
    height: 50px;
}

.userDropdownContent {
    display: block;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: -50%;
    top: 100%;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.6s ease, transform 0.6s ease, visibility 0.6s;
}

.userDropdown:hover .userDropdownContent {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.userOption {
    color: black;
    padding: 10px 15px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    text-align: left;
    display: block;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.userOption:hover {
    background-color: var(--main-font-color);
    color: white;
    border-radius: 4px;
    cursor: pointer;
}


.mobile-user-dropdown {
    position: absolute;
    background-color: white;
    width: 90%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 100;
    border-radius: 4px;
}


.mobile-dropdown-option {
    padding: 10px 20px;
    text-align: center;
    border-top: 1px solid #ccc;
    cursor: pointer;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: black;
    display: block;
    transition: background-color 0.3s ease, color 0.3s ease;

}

.mobile-dropdown-option:first-child {
    border: none;
}

.mobile-dropdown-option:hover {
    background-color: var(--main-font-color);
    color: white;
    border-radius: 4px;
}

.user-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    height: 50px;
    width: 50px;
}

.user-icon-button-image {
    height: 50px;
    width: 50px;
}