.events-page-banner-cards-container-div li {
  display: flex;
  justify-content: center;
  right: 20px;
}

.events-page-sub-banner-card {
  margin: 15px;
  width: 100%;
  /* height: 30vh; */
  color: white;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 300;
  text-align: center;
  border: none;
  border-radius: 10px;
  background: linear-gradient(180deg, #101c75cc 0%, #073e76cc 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  width: 400px;
  height: 220px;
}

.events-page-sub-banner-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--button-bg-image);
  background-size: cover;
  background-position: center;
}

.events-page-sub-banner-card:hover {
  transform: scale(1.05);
  text-shadow: #fc0 1px 0 10px;
  box-shadow: 0 0 15px rgba(255, 204, 0, 0.9);
}

.events-page-sub-banner-card span {
  color: #e3ad05;
  font-family: var(--main-font-family);
  font-weight: 400;
  font-size: 28px;
  letter-spacing: 5px;
  background: linear-gradient(360deg, #08196a 0%, #064c7a 100%);
  border-radius: 40px;
  width: 85%;
  height: 14%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

@media (max-width: 1600px) {
  .events-page-sub-banner-card::before {
    background-size: 100% 100%;
  }
}

@media (min-width: 2500px) {
  .events-page-sub-banner-card::before {
    background-size: 100% 100%;
  }
}