*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.desktopLogo {
  width: 240px;
  height: 50px;
}

.header {
  position: fixed;
  width: 100vw;
  height: 100px;
  background-image: linear-gradient(90deg, rgba(2, 96, 152, 1), rgba(7, 16, 125, 1));
  padding: 25px 30px;
  color: white;
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  opacity: 0.9;
  z-index: 2;
}

.headerButtons {
  margin-left: auto;
  display: flex;
}

.headerButtons>* {
  margin-right: 20px;
}

.headerButtons>* {
  margin-right: 20px;
}

.welcomeUsername {
  color: var(--primary-text-color);
  font-family: var(--main-font-family);
  font-size: 22px;
  font-weight: 600;
  padding-right: 20px;
  padding-top: 10px;
  margin-left: -10px;
}

.welcomeText {
  color: var(--primary-text-color);
  font-family: var(--main-font-family);
  font-size: 22px;
  font-weight: 400;
  padding-top: 10px;
  margin-left: auto;
}

.localization-button-container-div {
  grid-row: 3 / 4;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.localization-button-container-div button img {
  /* width: 40px;
  height: 30px; */
}

.localization-button-container-div .nav-button-lang {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;
}

.localization-button-container-div .nav-button-lang:hover {
  transition: filter 2s ease;
  filter: brightness(1.2);
  transform: scale(1.2);
}

/* Mobile CSS */

.header-mobile {
  position: fixed;
  width: 100vw;
  background-image: linear-gradient(90deg, rgba(2, 96, 152), rgba(7, 16, 125));
  padding: 25px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}

.spacer-left {
  flex: 1;
  /* Takes up remaining space to push logo to center */
}

.mobileLogo {
  width: 220px;
  height: 46px;
}

.mobile-localization-button-container-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.mobile-localization-button-container-div button img {
  width: 27px;
}

.mobile-localization-button-container-div .nav-button-lang {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;
  padding-right: 5px;
  /* Added padding to the right */
}

.mobile-localization-button-container-div .nav-button-lang:hover {
  transition: filter 2s ease;
  filter: brightness(1.2);
  transform: scale(1.2);
}