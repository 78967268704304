.login-popup-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  padding-top: 40px;
  z-index: 5;
}

.signInMessage {
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 600;
}

.login-mobile-page-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 25px;
  width: 80vw;
  max-width: 500px;
  max-height: 450px;
  box-sizing: border-box;
}

.login-popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #08236E;
  padding: 20px;
  position: relative;
  text-align: center;
}

.login-popup-header h1 {
  margin: 0;
  font-family: var(--main-font-family);
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  flex-grow: 1;
}

.login-close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 20px;
}

.login-close img {
  width: 100%;
  height: 100%;
}

.login-close:hover {
  transform: scale(1.05);
}


.login-popup-form {
  padding: 20px 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-label {
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  font-family: var(--main-font-family);
  color: #333;
  font-size: 16px;
  font-weight: 600;
}

.login-input {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.login-input:focus {
  border-color: var(--main-font-color);
  border-width: 2px;
  outline: none;
}

.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.remember-me-checkbox {
  margin-right: 10px;
}

.remember-me-label {
  font-size: 14px;
  font-family: var(--main-font-family);
  color: #333;
}

.login-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background: #0056b3;
}

.login-popup-footer {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.login-message {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  text-align: center;
}

.login-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.login-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.password-input-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}