.sub-banner-cards-container-div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.react-multiple-carousel__arrow--left {
  left: 0;
  z-index: 0;
}

.react-multiple-carousel__arrow--right {
  right: 0;
  z-index: 0;
}