.referrals-page-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    backdrop-filter: blur(10px);
}

.referrals-header {
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 20px;
    align-self: flex-start;
    /* Align header to the left */
}

.referrals-content {
    display: flex;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.referrals-table-container {
    width: 70%;
    border-radius: 8px;
    padding: 20px;
}

.referral-link-container {
    width: 25%;
    color: white;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.referral-link-container .input-container {
    width: 100%;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .referrals-content {
        flex-direction: column;
    }

    .referral-link-container {
        width: 100%;
        margin-bottom: 20px;
    }

    .referrals-table-container {
        width: 100%;
    }
}

.dtable-referrals-loading {
    color: white;
    font-weight: 600;
}