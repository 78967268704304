.terms {
    text-align: left;
    color: var(--primary-text-color);
    font-family: var(--main-font-family);
    padding: 20px;

}

.terms-content {
    text-align: left;
    color: var(--primary-text-color);
    font-family: var(--main-font-family);
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 8px;
}

.terms-header {
    font-size: 40px;
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
}

/* Mobile CSS */
.mobile-terms {
    text-align: left;
    color: var(--primary-text-color);
    font-family: var(--main-font-family);
    padding: 1vh 2vw;
}