.account-info-container {
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .bonus-token-reward-program {
        font-family: var(--main-font-family);
        color: var(--primary-text-color);
        text-align: left;
        padding: 20px;
    }

    .bonus-token-reward-program .main-title {
        font-size: 40px;
        background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: var(--main-font-family);
        font-weight: 800;
    }

    .bonus-token-reward-program .bonus-token-reward-program-content {
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        padding: 20px;
        border-radius: 8px;
    }

    .bonus-token-reward-program h2 {
        font-size: 24px;
    }

    .bonus-token-reward-program p,
    .bonus-token-reward-program ul,
    .bonus-token-reward-program li {
        font-size: 18px;
        line-height: 1.6;
    }

    .bonus-token-reward-program ul {
        list-style-type: disc;
        margin-left: 20px;
    }

    .bonus-token-reward-program .formula {
        font-weight: bold;
    }

    .bonus-token-reward-program .happy-playing {
        font-style: italic;
        margin-top: 20px;
    }

    .bonus-token-reward-program .signature {
        font-size: 24px;
        background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: var(--main-font-family);
        font-weight: 800;
        margin-top: 20px;
    }

    flex-direction: column-reverse;
    /* By default, arrange elements in reverse order for mobile view */
}

.account-info-form,
.account-info-qr {
    width: 100%;
}

.account-info-form {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.form-group {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 8px;
    font-size: 16px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    color: white;
    text-align: left;
}

.form-group input,
.form-group .date-picker {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--main-font-color);
    background-color: #172644;
    color: white;
}

.form-group input[readonly],
.form-group input[disabled] {
    color: rgb(177, 177, 177);
    cursor: not-allowed;
    background-color: #6969697e;
}

.inline-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
}

.inline-group .form-group {
    width: 48%;
}

.section-divider {
    width: 100%;
    max-width: 500px;
    height: 1px;
    background-color: var(--main-font-color);
    margin: 20px 0;
}

.account-info-qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
}

.qr-code-link {
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
}

.input-container {
    width: 60%;
}

@media (min-width: 768px) {
    .account-info-container {
        flex-direction: row;
        /* Revert back to row layout for larger screens */
    }

    .account-info-form,
    .account-info-qr {
        width: 45%;
    }



}

@media (max-width: 768px) {
    .input-container {
        width: 100%;
    }

    .copy-address {
        margin-bottom: 20px;
    }
}