.for-german.content {
    margin-left: 140px;
}

.content {
    padding-top: 120px;
    padding-bottom: 110px;
    margin-left: 120px;
    padding-right: 20px;
    min-height: var(--content-min-height);
}

.mobile-content {
    padding-top: 120px;
    padding-bottom: 120px;
    padding-right: 21px;
    padding-left: 20px;
    min-height: var(--content-min-height);
    overflow: hidden;
}