.register-popup-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  overflow-y: auto;
}

.register-mobile-page-1 {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 25px;
  width: 90vw;
  max-width: 700px;
  max-height: 95vh;
  overflow-y: auto;
}

.register-popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #08236E;
  padding: 15px 0;
  margin: 0;
  width: 100%;
  position: relative;
  text-align: center;
  flex-shrink: 0;
}

.register-popup-header h1 {
  margin: 0;
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  flex-grow: 1;
}

.register-close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 20px;
}

.register-close img {
  width: 100%;
  height: 100%;
}

.register-close:hover {
  transform: scale(1.05);
}

.register-popup-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.register-popup-content .react-datepicker-wrapper {
  width: 100%;
}

.register-popup-content .react-datepicker__input-container {
  width: 100%;
}

.register-popup-content .react-datepicker__input-container input {
  width: 100%;
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-row {
  display: flex;
  padding-bottom: 10px;
  width: 100%;
  gap: 20px;
}

.form-item {
  flex: 1;
}

.full-width {
  width: 100%;
}

.register-label {
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  font-family: var(--main-font-family);
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.register-input {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.register-input:focus {
  border-color: var(--main-font-color);
  border-width: 2px;
  outline: none;
}

.terms-acceptance {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.terms-acceptance-checkbox {
  margin-right: 10px;
}

.terms-acceptance-label {
  font-size: 14px;
  font-family: var(--main-font-family);
  color: #333;
}

.terms-link {
  font-weight: bold;
  text-decoration: underline;
}

.register-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register-button:hover {
  background: #0056b3;
}

.register-popup-footer {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.register-message {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  text-align: center;
}

.register-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.register-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.password-input-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  margin-bottom: 8px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.proceed-loading-spinner {
  width: 25px;
  height: 25px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-left-color: #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.registration-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.success-message {
  font-size: 40px;
  background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--main-font-family);
  font-weight: 800;
  margin-bottom: 20px;
}

.otp-popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #08236E;
  padding: 20px;
  position: relative;
  text-align: center;
  margin: 0;
  font-family: var(--main-font-family);
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  flex-shrink: 0;
}

.otpSubtext {
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 400;
  color: #333;
  margin-bottom: 20px;
}

.otp-popup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 35px;
  flex-grow: 1;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.otp-input {
  width: 40px;
  height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: var(--main-font-family);
  font-weight: 600;
  margin: 0 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.otp-input:focus {
  border-color: var(--main-font-color);
  outline: none;
}

.otp-timer {
  margin-top: 20px;
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 400;
  color: #333;
}

.resend-code {
  background-color: transparent;
  border: none;
  color: var(--main-font-color);
  cursor: pointer;
  font-family: var(--main-font-family);
  font-weight: 600;
  font-size: 14px;
}

.otp-error {
  color: red;
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.security-error {
  color: red;
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.otp-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.validate-button {
  background: linear-gradient(to bottom, #E5AF05, #7F6103);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.validate-button:disabled {
  background: linear-gradient(to bottom, #E5AF05, #7F6103);
  cursor: not-allowed;
}

.validate-button:hover:not(:disabled) {
  background: linear-gradient(to bottom, #e2b729, #ac8920);
}

.skip-button {
  background: linear-gradient(180deg, rgba(81, 133, 233, 1), rgba(16, 48, 109, 1));
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 600;
  width: 100%;
}

.skip-button:hover {
  background: linear-gradient(180deg, rgba(101, 153, 253, 1), rgba(36, 68, 129, 1));
}

.otp-loading-spinner {
  width: 21px;
  height: 21px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-left-color: #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.security-word-input {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-family: var(--main-font-family);
  font-size: 14px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  resize: none;
}

.security-word-input:focus {
  border-color: var(--main-font-color);
  outline: none;
}

.otp-close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 20px;
}

.otp-close img {
  width: 100%;
  height: 100%;
}

.otp-close:hover {
  transform: scale(1.05);
}