.bonus-token-reward-program {
    font-family: var(--main-font-family);
    color: var(--primary-text-color);
    text-align: left;
    padding: 20px;
}

.bonus-token-reward-program .main-title {
    font-size: 40px;
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
}

.bonus-token-reward-program .bonus-token-reward-program-content {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 8px;
}

.bonus-token-reward-program h2 {
    font-size: 24px;
}

.bonus-token-reward-program p,
.bonus-token-reward-program ul,
.bonus-token-reward-program li {
    font-size: 18px;
    line-height: 1.6;
}

.bonus-token-reward-program ul {
    list-style-type: disc;
    margin-left: 20px;
}

.bonus-token-reward-program .formula {
    font-weight: bold;
}

.bonus-token-reward-program .happy-playing {
    font-style: italic;
    margin-top: 20px;
}

.bonus-token-reward-program .signature {
    font-size: 24px;
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
    margin-top: 20px;
}

.custom-accordion .accordion__button {
    font-family: var(--main-font-family);
    font-weight: 800;
    background-image: linear-gradient(90deg, rgba(2, 96, 152, 1), rgba(7, 16, 125, 1));
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.custom-accordion .accordion__button:hover {
    opacity: 0.9;
}

.custom-accordion .accordion__panel {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}

.custom-accordion .accordion__item {
    margin-bottom: 10px;
}