.event-section-wrapper {
  overflow: hidden;
  position: relative;
  transition: height 0.5s ease-in-out;
}

.event-section-wrapper .react-multi-carousel-list {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.carousel-view {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  overflow: hidden;
  scroll-behavior: smooth;
  height: 160px;
}

.event-grid-view {
  display: grid;
  gap: 20px;
  padding: 10px 20px 40px 20px;
  overflow: hidden;
}

/* Default grid layout for larger screens */
.event-grid-view {
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

/* 2 columns grid layout for small screens */
@media (max-width: 768px) {
  .event-grid-view {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 10px 10px 10px 10px;
  }

  .event-section-wrapper .react-multi-carousel-list {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

}

.event-item {
  width: 200px;
  height: 130px;
  background: linear-gradient(180deg, #101C75CC 0%, #073E76CC 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}

.event-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.view-all-button {
  margin-left: auto;
  background-color: #4a90e2;
  color: white;
  font-size: 16px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  padding: 6px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.view-all-button:hover {
  background-color: #357abd;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
}

.homeCategoryIcon {
  margin-right: 10px;
}

.arrow-button {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  transition: box-shadow 0.3s ease, color 0.3s ease;
}

.arrow-button.left {
  left: 0;
}

.arrow-button.right {
  right: 0;
}

.arrow-button:hover {
  box-shadow: 0 0 5px 1px gold;
  color: gold;
}