.loader-container {
    display: flex;
    height: 90vh;
    justify-content: center;
    align-items: center;
}

.loader-container .loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: white #0000;
    animation: l1 1s infinite;
}
@keyframes l1 {to{transform: rotate(.5turn)}}
