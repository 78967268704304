.cancelwithdrawal-popup-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  padding-top: 40px;
  z-index: 3;
}

.cancelwithdrawal-mobile-page-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 25px;
  width: 80vw;
  max-width: 500px;
  max-height: 250px;
  box-sizing: border-box;
}

.cancelwithdrawal-popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #08236E;
  padding: 20px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color: #fff;
  font-family: var(--main-font-family);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.cancelwithdrawalSubtext {
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 400;
  color: #333;
}

.cancelwithdrawal-popup-form {
  padding: 20px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancelwithdrawal-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cancelwithdrawal-confirm-button,
.cancelwithdrawal-cancel-button {
  width: 45%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancelwithdrawal-confirm-button {
  background: linear-gradient(to bottom, #E5AF05, #7F6103);
  color: #fff;
}

.cancelwithdrawal-confirm-button:hover {
  background: linear-gradient(to bottom, #e2b729, #ac8920);
}

.cancelwithdrawal-cancel-button {
  background: #ddd;
  color: #333;
}

.cancelwithdrawal-cancel-button:hover {
  background: #bbb;
}