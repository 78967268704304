.account-settings-date-picker-button {
  width: 35vw;
  text-align: center;
  border-radius: 5px;
  height: 40px;
  font-family: var(--main-font-family);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: #172644;
  color: white;
  border: 1px solid var(--main-font-color);
}

.account-settings-date-picker-button svg {
  position: absolute;
  right: 5%;
}

.clear-filter-button {
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 20px;
  background-color: transparent;
  color: white;
  font-family: var(--main-font-family);
  border: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
}

.clear-filter-button svg {
  width: 28px;
  height: 28px;
}

.clear-filter-button:hover {
  background-color: var(--main-font-color);
}

@media (max-width: 768px) {
  .account-settings-date-picker-button {
    width: 58vw;
  }
}

@media (max-width: 540px) {
  .account-settings-date-picker-button {
    font-size: 12px;
    padding: 10px;
    height: 35px;
  }

  .account-settings-date-picker-button {
    width: 45vw;
  }

  .account-settings-date-picker-button svg {
    display: none;
  }

  .clear-filter-button {
    font-size: 10px;
  }
}

@media (max-height: 600px) {
  .account-settings-date-picker-button {
    font-size: 10px;
    padding: 10px;
    height: 20px;
  }

  .account-settings-date-picker-button svg {
    width: 20px;
  }

  .clear-filter-button {
    font-size: 10px;
    padding: 5px 10px;
  }
}