.partner-button {
  width: 180px;
  height: 80px;
  color: white;
  font-size: 16px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 300;
  text-align: center;
  border-radius: 5px;
  background: #172644;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.partner-button:hover {
  box-shadow: 0 0 6px 1px gold;
}