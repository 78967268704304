/* EventButton.css */
.event-joined-button {
  width: 400px;
  height: 400px;
  color: white;
  font-size: 16px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 300;
  text-align: center;
  border: none;
  border-radius: 30px;
  /* background: linear-gradient(180deg, #101c75cc 0%, #073e76cc 100%); */
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  /* Prevent shrinking */
  z-index: 0;
}

.event-joined-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--button-bg-image);
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

.event-joined-button:hover::before {
  opacity: 1;
}

.event-joined-button:hover {
  box-shadow: 0 0 6px 1px gold;
}

.event-joined-button span {
  position: relative;
  z-index: 0;
  transition: font-weight 0.3s ease-in-out, color 0.3s;
}

.event-joined-button:hover span {
  font-weight: 600;
  text-shadow: rgb(0, 0, 0) 2px 0 10px;
}

.live-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  background-color: green;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 5px;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    text-shadow 0.3s ease-in-out;
}

.live-icon::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(138, 255, 138);
  border-radius: 50%;
  margin-right: 5px;
}

.event-joined-button:hover .live-icon {
  opacity: 1;
  box-shadow: 0 0 10px green;
  text-shadow: 0 0 5px white;
}

.event-joined-button:hover .live-icon::before {
  box-shadow: 0 0 5px rgb(138, 255, 138);
}

@media (max-width: 900px) {
  .event-joined-button {
    width: 400px;
    height: 400px;
    color: white;
    font-size: 16px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 300;
    text-align: center;
    border: none;
    border-radius: 10px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    /* Prevent shrinking */
    z-index: 0;
  }
}

/* @media (max-width: 800px) {
  .event-joined-button {
    width: 335px;
    height: 335px;
  }
}

@media (max-width: 715px) {
  .event-joined-button {
    width: 275px;
    height: 275px;
  }
} */

@media (max-width: 600px) {

  /* The above breakpoint should be whatever responsive.mobileSmall.breakpoint.max is in EventJoinedSection.jsx  */
  .event-joined-button {
    width: 100%;
    height: unset;
    aspect-ratio: 1/1;
  }
}