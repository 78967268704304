.no-data-component {
  color: white;
  font-size: 14px;
  width: 100%;
  font-family: var(--main-font-family);
  text-align: center;
  padding: 20px;
  background-color: #172644;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.total-referral-profit {
  font-family: var(--main-font-family), sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: white;
  margin-right: auto;
}

.total-referral-profit .profit-value {
  background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--main-font-family);
  font-weight: 800;
}

.d-table-referrals-container {
  padding-top: 12px;
  width: 100%;
  color: white;
}

.d-table-referrals-input-group {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  z-index: 2;
}

.date-picker-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-filter-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.d-table-referrals-container .rdt_Pagination button svg {
  height: 25px;
  fill: white;
}

.d-table-referrals-container .rdt_TableRow {
  color: white;
}

@media (max-height: 595px) {
  .d-table-referrals-input-group {
    margin-bottom: 0;
  }

  .d-table-referrals-container .rdt_Pagination span {
    position: relative;
    top: -8px;
  }

  .d-table-referrals-container .rdt_Pagination button {
    padding: 0;
    position: relative;
  }

  .d-table-referrals-container .rdt_Pagination select {
    position: relative;
    bottom: 8px;
    left: 5px;
    text-align: right;
  }

  .d-table-referrals-container .rdt_Pagination select+svg {
    position: relative;
    top: -10px;
  }

  .d-table-referrals-container .rdt_Pagination button svg {
    height: 15px;
    position: absolute;
    top: 2px;
    fill: white;
  }
}

.d-table-referrals-container .rdt_TableHeadRow {
  background-image: linear-gradient(90deg, rgba(2, 96, 152, 1), rgba(7, 16, 125, 1)) !important;
  color: white !important;
}

.d-table-referrals-container .rdt_TableCell {
  color: white !important;
  border-bottom: 1px solid white !important;
}

.d-table-referrals-container .referrals-date-picker {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--main-font-color);
  background-color: #172644;
  color: white;
}

.d-table-referrals-container .rdt_Pagination {
  color: white !important;
  background-color: transparent !important;
}

.d-table-referrals-container .rdt_Pagination button {
  color: white !important;
}

.d-table-referrals-container .rdt_Pagination select {
  color: white !important;
}

.d-table-referrals-container .rdt_Pagination button svg {
  fill: white !important;
}

.d-table-referrals-container .rdt_TableRow:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
  /* Optional for alternating row colors */
}

@media (max-width: 768px) {
  .total-referral-profit {
    font-family: var(--main-font-family), sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: white;
    margin-right: auto;
  }
}