.category-page {
  padding: 20px;
}

.category-page-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
}

.header-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-center {
  flex: 1;
  display: flex;
  justify-content: center;
}

.header-right {
  width: 40px;
  /* Adjust width to maintain layout consistency */
}

.main-category-header {
  font-size: 30px;
  background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--main-font-family);
  font-weight: 800;
  text-align: center;
  margin: 10px 0;
}

.category-icon-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-search-bar {
  width: 100%;
  margin-bottom: 10px;
}

.category-filter-select {
  width: 100%;
}

.category-games-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 2rem;
}

.category-games-container>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-games-container .casino-button {
  height: unset;
  aspect-ratio: 2/1;
  width: 100% !important;
  max-width: 300px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.category-search-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* Set a fixed width or max-width if necessary */
}

.category-search-bar.search-button {
  position: absolute;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;
  color: var(--main-font-color);
  border: 2px solid var(--main-font-color);
  border-radius: 5px;
  font-family: var(--main-font-family);
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination button:hover {
  background-color: var(--main-font-color);
  color: white;
}

.pagination button:disabled {
  background-color: transparent;
  color: #9e9e9e;
  border: 2px solid #9e9e9e;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  font-size: 16px;
  font-family: var(--main-font-family);
  color: #F2B902;
  font-weight: 600;
}

.showing-text {
  text-align: center;
  margin-bottom: 10px;
  color: #F2B902;
  font-family: var(--main-font-family);
  font-weight: 600;
}

.gold-text {
  color: #F2B902;
}

.load-more-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.load-more-button,
.go-to-top-button {
  margin: 5px 0;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;
  color: var(--main-font-color);
  border: 2px solid var(--main-font-color);
  border-radius: 5px;
  font-family: var(--main-font-family);
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.load-more-button:hover,
.go-to-top-button:hover {
  background-color: var(--main-font-color);
  color: white;
}

.go-to-top-button {
  border: 2px solid #F2B902;
  color: #F2B902;
}

.go-to-top-button:hover {
  background-color: #F2B902;
  color: white;
}

@media (min-width: 768px) {
  .category-page-header {
    align-items: center;
  }

  .main-category-header {
    font-size: 40px;
    margin: 0;
  }

  .category-icon-container {
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
  }

  .category-search-bar {
    width: 250%;
    margin-bottom: 0;
  }

  .category-filter-select {
    margin-left: 10px;
  }
}

@media (max-width: 480px) {
  .category-games-container .casino-button {
    max-width: 100%;
  }

  .category-icon-container {
    margin-top: 10px;
    flex-direction: column;
    width: 100%;
  }

  .category-filter-select {
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {

  .category-games-container .casino-button {
    max-width: 100%;
  }

  .category-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }

  .category-games-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}