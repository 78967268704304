.depositContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}


.popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #08236e;
  padding-top: 25px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  text-align: center;
}

.popup-header h1 {
  margin: 0;
  font-family: var(--main-font-family);
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  flex-grow: 1;
}

.close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 20px;
}

.close img {
  width: 100%;
  height: 100%;
}

.close:hover {
  transform: scale(1.05);
}

.popup-form {
  padding: 20px 35px;
}

.popup-form h2 {
  text-align: left;
}

.popup-form p {
  text-align: left;
}

.errorMessage {
  color: red;
  margin-top: 5px;
  font-family: var(--main-font-family);
  font-size: 14px;
}

.disclaimer {
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  padding-top: 0px;
  margin-bottom: 20px;
  /* Add spacing beneath the element */
}

.popup-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.disclaimer-button {
  background-color: transparent;
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  padding-top: 0px;
  text-decoration: underline;
  border: none;
}

.disclaimer-button:hover {
  background-color: transparent;
}

.disclaimer-visible {
  width: 450px;
  height: 125px;
  overflow: auto;
  scrollbar-color: #036db1 transparent;
  scrollbar-width: thin;
  text-align: left;
}

.disclaimer-not-visible {
  display: none;
}

.depositContainer .input-container {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #036DB1;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  width: 90%;
  position: relative;
}

.depositContainer .text-input {
  border: none;
  outline: none;
  font-family: var(--main-font-family);
  font-size: 14px;
  color: #6E6E6E;
  background-color: transparent;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 40px;
  /* Space to accommodate the icon */
}

.depositContainer .copy-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 10px;
  /* Spacing to the left of the copy icon */
  position: absolute;
  right: 10px;
  /* Position it inside the input container */
}

.depositContainer .input-container::after {
  content: "";
  position: absolute;
  right: 40px;
  /* Adjust to fit the copy icon */
  width: 20px;
  /* Width of the fade effect */
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.depositContainer .copyText {
  padding-top: 10px;
  font-family: var(--main-font-family);
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}