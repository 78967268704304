.event-joined-section-wrapper {
  overflow: hidden;
  position: relative;
  transition: height 0.5s ease-in-out;
}

.event-joined-section-carousel-view {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  overflow: hidden;
  scroll-behavior: smooth;
  height: 300px;
}

.event-joined-section-grid-view {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 20px 40px 20px;
  overflow: hidden;
}

.event-joined-section-grid-view-mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 10px 20px 40px 20px;
}

.event-joined-section-event-item {
  width: 200px;
  height: 130px;
  background: linear-gradient(180deg, #101c75cc 0%, #073e76cc 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}

.event-joined-section-event-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.view-all-button {
  margin-left: auto;
  background-color: #4a90e2;
  color: white;
  font-size: 16px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  padding: 6px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.view-all-button:hover {
  background-color: #357abd;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
}

.homeCategoryIcon {
  margin-right: 10px;
}

.centering-single-buttons li,
.centering-single-buttons div.event-joined-section {
  display: flex;
  justify-content: center;
}

.centering-single-buttons li {
  right: 20px;
}

.event-joined-section-wrapper .react-multi-carousel-list {
  padding-left: 20px !important;
  padding-right: 0px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}