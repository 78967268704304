.payment-failed-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.payment-failed-popup {
    background: white;
    width: 80%;
    max-width: 600px;
    border-radius: 25px;
    height: 55%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.bankPopupHeader {
    margin: 0;
    font-family: var(--main-font-family);
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    flex-grow: 1;
    padding-bottom: 10px;

}

.bank-popup-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background: #fff;
    padding: 20px;
    position: relative;
    text-align: center;
}

.failed-message {
    padding: 30px;
}

.payment-failed-header {
    font-family: var(--main-font-family);
    font-size: 36px;
    font-weight: 600;
    padding-top: 20px;
}

.payment-failed-subtext {
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}

.payment-failed-close {
    border-radius: 4px;
    background-image: linear-gradient(180deg,
            rgba(248, 190, 0, 1),
            rgba(125, 91, 26, 1));
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 11px 20px;
    min-width: 120px;
    width: 50%;
    border: none;
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;
}

.payment-failed-close:hover {
    border-radius: 4px;
    background-image: linear-gradient(180deg,
            rgba(248, 190, 0, 1),
            rgb(141, 99, 22));
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 11px 20px;
    min-width: 120px;
    width: 50%;
    border: none;
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;
}

@media (max-width: 768px) {
    .payment-failed-popup {
        background: white;
        width: 90%;
        border-radius: 25px;
        height: 80%;
        position: relative;
        display: flex;
        flex-direction: column;
    }
}