/* src/components/SearchBar/SearchBar.css */
.search-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* Set a fixed width or max-width if necessary */
}

.search-bar input {
  width: 100%;
  padding: 5px 40px 5px 10px;
  /* Increase padding to avoid cutting off the icon */
  border: 2px solid var(--main-font-color);
  border-radius: 4px;
  background: transparent;
  color: #fff;
  /* Ensure text color is white */
}

.search-bar input::placeholder {
  color: #fff;
  /* Placeholder color */
  font-family: var(--main-font-family);
  font-size: 16px;
  font-weight: 400;
}

.search-button {
  position: absolute;
  right: 30px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button svg {
  fill: white;
  stroke: white;
  stroke-width: 1;
}


@media (max-width: 768px) {
  .search-bar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 0rem;
    padding-left: 0px;
    padding-right: 0px;
  }

  .search-button {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}