.settings-container {
    padding: 20px;
    background-color: transparent;
}

.settings-content {
    border: none;
    border-radius: 4px;
    background-color: transparent;
}

.settings-tabs {
    display: flex;
    border-bottom: 1px solid white;
}

.settings-tabs a,
.settings-tabs .disabled-tab {
    padding: 10px 20px;
    text-decoration: none;
    color: var(--main-font-color);
    border-top: 1px solid var(--main-font-color);
    border-left: 1px solid var(--main-font-color);
    border-right: 1px solid var(--main-font-color);
    margin-right: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 16px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    background-color: transparent;
    position: relative;
    transition: color 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
}

.settings-tabs a::after,
.settings-tabs .disabled-tab::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 0;
    background-color: white;
}

.settings-tabs a:hover::after,
.settings-tabs .disabled-tab:hover::after {
    height: 4px;
}

.settings-tabs a:hover {
    color: white;
    background-color: var(--main-font-color);
}

.settings-tabs a.active-tab {
    background-color: var(--main-font-color);
    color: white;
    border-color: var(--main-font-color);
    border-bottom: 1px solid white;
}

.settings-tabs a.active-tab::after {
    height: 4px;
    background-color: white;
}

.settings-tabs .disabled-tab {
    color: grey;
    cursor: not-allowed;
    /* Disable click events */
    border-top: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    margin-right: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 16px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    background-color: transparent;
    position: relative;
    transition: color 0.3s ease, background-color 0.3s ease;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
    .settings-tabs {
        flex-direction: column;
        border-bottom: none;
    }

    .settings-tabs a,
    .settings-tabs .disabled-tab {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        padding: 8px 15px;
        /* Decreased padding */
        border-bottom: 1px solid var(--main-font-color);
    }

    .settings-tabs .disabled-tab {
        border-bottom: 1px solid grey;
    }

    .settings-tabs a {
        padding: 8px 15px;
        /* Decreased padding */
    }

    .settings-tabs a::after,
    .settings-tabs .disabled-tab::after {
        display: none;
    }

    .settings-tabs a.active-tab {
        background-color: var(--main-font-color);
        color: white;
        border-color: var(--main-font-color);
        border-bottom: 4px solid white;
    }


}