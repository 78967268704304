.casino-button {
  width: 230px;
  height: 120px;
  color: white;
  font-size: 16px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 300;
  text-align: center;
  border: none;
  border-radius: 10px;
  background: linear-gradient(180deg, #101c75cc 0%, #073e76cc 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  /* Prevent shrinking */
  z-index: 0;
}

.hidden-image {
  display: none;
}

.button-text {
  color: white;
  font-size: 18px;
  text-align: center;
}

.casino-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--button-bg-image);
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

.casino-button:hover::before {
  opacity: 1;
}

.casino-button:hover {
  box-shadow: 0 0 6px 1px gold;
}

.casino-button span {
  position: relative;
  z-index: 0;
  transition: font-weight 0.3s ease-in-out, color 0.3s;
}

.casino-button:hover span {
  font-weight: 600;
  text-shadow: rgb(0, 0, 0) 2px 0 10px;
}

.casino-button:hover .live-icon {
  opacity: 1;
  box-shadow: 0 0 10px green;
  box-shadow: 0 0 10px green;
  text-shadow: 0 0 5px white;
}

.casino-button:hover .live-icon::before {
  box-shadow: 0 0 5px rgb(138, 255, 138);
}

@media (max-width: 500px) {

  /* The above breakpoint should be whatever responsive.tiny.breakpoint.max is in CasinoSection.jsx  */
  .casino-button {
    height: unset;
    aspect-ratio: 23/12;
    width: 95%;
  }
}