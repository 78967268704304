/* GamePage.css */
.loadingGame {
  font-size: 40px;
  background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--main-font-family);
  font-weight: 800;
  text-align: center;
  /* Center the text within the container */
}

.game-page-container {
  text-align: center;
  padding: 0px;
}

.game-page-container.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  /* Optional: Add background color */
  z-index: 9999;
  /* Ensure it is on top of other elements */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  margin-top: 15px;
}

.back-button {
  border: 2px solid var(--main-font-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  left: 220px;
}

.back-button:hover {
  box-shadow: 0 0 10px var(--main-font-color);
}

.game-title {
  font-size: 40px;
  background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--main-font-family);
  font-weight: 800;
  text-align: center;
  margin: 0 auto;
  /* Center the title within the header */
}

.responsive-iframe {
  width: 90%;
  height: 70vh;
  /* 70% of the viewport height */
  border: none;
  /* Optional: Remove default border */
}

.game-page-container.full-screen .responsive-iframe {
  width: 100%;
  height: 100%;
}

.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 25px;
}

.game-page-container.full-screen .game-page-header {
  height: 25px;
  /* Reduce size by 50% */
  margin-bottom: 3px;
  margin-top: 4px;
  /* Reduce margin */
}

.game-page-container.full-screen .game-page-header .back-button {
  width: 20px;
  height: 20px;
}

.game-page-container.full-screen .game-page-header .back-button .back-arrow-icon {
  width: 15px;
  height: 15px;
}

.game-page-container.full-screen .game-page-header .game-title {
  display: none;
}

.game-page-container.full-screen .game-page-header .fullscreen-button .fullscreen-icon {
  width: 20px;
  height: 20px;
}


.button-container-game {
  display: flex;
  grid-gap: 0px;
  justify-content: center;
  align-items: center;
}

.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2F2F2F;
  border: none;
  color: #fff;
  font-size: 16px;
  font-family: var(--main-font-family);
  font-weight: 800;
  padding: 10px 40px; /* Increased padding to ensure the text fits in one line */
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0;
  white-space: nowrap; /* Prevent text wrapping */
}

.toggle-button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.toggle-button:nth-child(2) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.toggle-button.active {
  background: linear-gradient(to bottom, #5185E9, #043B5D);
}

.toggle-button:hover {
  background-color: #555;
}

.toggle-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.toggle-button.disabled:hover {
  background-color: initial;
}

.earn-mode-container {
  position: relative;
  display: inline-block;
}

.earn-options {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  z-index: 1000;
}

.toggle-button + .toggle-button {
  margin-left: 0;
}

.earn-mode-container .earn-options .toggle-button.cash,
.earn-mode-container .earn-options .toggle-button.bonus {
  background-color: #2F2F2F;
  padding: 10px 40px;
  color: #fff;
}

.earn-mode-container .earn-options {
  top: auto;
  margin-bottom: 10px;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent; 
  border-right: 7px solid transparent; 
  border-top: 7px solid #fff;
  border-bottom: 7px solid #fff; 
  margin-left: 10px;
  transition: border-top 0.3s ease, border-bottom 0.3s ease;
}

.triangle.up {
  border-bottom: none;
}

.triangle.down {
  border-top: none;
}

.fullscreen-button,
.exit-fullscreen-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 6%;
  background: none;
  border: none;
  cursor: pointer;
}

.fullscreen-icon {
  width: 32px;
  height: 32px;
}

@media (max-width: 800px) {
  .back-button {
    background-color: transparent;
    border: 2px solid var(--main-font-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    left: 20px;
  }

  .earn-options {
    display: flex;
    flex-direction: column;
  }

  .earn-options .toggle-button:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .earn-options .toggle-button:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .back-button {
    background-color: transparent;
    border: 2px solid var(--main-font-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    left: 50px;
  }
}

@media (max-width: 470px) {
  .back-button {
    background-color: transparent;
    border: 2px solid var(--main-font-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    left: 30px;
  }

  .game-title {
    font-size: 30px;
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
    text-align: center;
    margin: 0 auto;
    /* Center the title within the header */
  }
}
