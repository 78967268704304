.events-page-sub-banner-cards-container-div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.react-multiple-carousel__arrow--left {
  left: 0;
  z-index: 0;
}

.react-multiple-carousel__arrow--right {
  right: 0;
  z-index: 0;
}

.poker-container {
  transition: unset;
  cursor: not-allowed;
  filter: grayscale(1);
}

.poker-container .events-page-sub-banner-card {
  transition: unset;
  cursor: not-allowed;
  filter: grayscale(1);
}

.sports-container {
  transition: unset;
  cursor: not-allowed;
  filter: grayscale(1);
}

.sports-container .events-page-sub-banner-card {
  transition: unset;
  cursor: not-allowed;
  filter: grayscale(1);
}

.events-page-banner-cards-container-div .react-multi-carousel-list {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}