.view-all-page {
  padding: 20px;
}

.view-all-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-arrow {
  font-size: 30px;
  cursor: pointer;
  border: 2px solid var(--main-font-color);
  border-radius: 50%;
  padding: 5px;
}

.back-arrow:hover {
  box-shadow: 0 0 10px var(--main-font-color);
}

.main-header {
  font-size: 30px;
  background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--main-font-family);
  font-weight: 800;
  text-align: center;
  margin: 10px 0;
}

.filter-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.filter-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #F2B902;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  margin-top: 10px;
}

.filter-icon:hover {
  color: #eec334;
  text-shadow: 0 0 5px var(--main-font-color), 0 0 10px var(--main-font-color), 0 0 15px var(--main-font-color);
}

.filter-icon span {
  margin-left: 5px;
  font-size: 16px;
  font-family: var(--main-font-family);
  font-weight: 600;
  color: #F2B902;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.filter-icon:hover span {
  color: #eec334;
  text-shadow: 0 0 5px var(--main-font-color), 0 0 10px var(--main-font-color), 0 0 15px var(--main-font-color);
}

.filters-container {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.filter-select {
  width: 100%;
}

.filters-container h3 {
  margin: 10px 0;
}

.viewAll-search-bar {
  width: 100%;
  margin-bottom: 10px;
}

.viewAll-search-bar input {
  width: auto;
}

.view-all-games-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 2rem;
}

.view-all-games-container>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-all-games-container .casino-button {
  height: unset;
  aspect-ratio: 2/1;
  width: 100% !important;
  max-width: 300px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;
  color: var(--main-font-color);
  border: 2px solid var(--main-font-color);
  border-radius: 5px;
  font-family: var(--main-font-family);
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination button:hover {
  background-color: var(--main-font-color);
  color: white;
}

.pagination button:disabled {
  background-color: transparent;
  color: #9e9e9e;
  border: 2px solid #9e9e9e;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  font-size: 16px;
  font-family: var(--main-font-family);
  color: #F2B902;
  font-weight: 600;
}

.showing-text {
  text-align: center;
  margin-top: 15px;
  color: #F2B902;
  font-family: var(--main-font-family);
  font-weight: 600;
}

.gold-text {
  color: #F2B902;
}

.load-more-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.load-more-button,
.go-to-top-button {
  margin: 5px 0;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;
  color: var(--main-font-color);
  border: 2px solid var(--main-font-color);
  border-radius: 5px;
  font-family: var(--main-font-family);
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.load-more-button:hover,
.go-to-top-button:hover {
  background-color: var(--main-font-color);
  color: white;
}

.go-to-top-button {
  border: 2px solid #F2B902;
  color: #F2B902;
}

.go-to-top-button:hover {
  background-color: #F2B902;
  color: white;
}

@media (min-width: 768px) {
  .view-all-header {
    flex-direction: row;
  }

  .main-header {
    font-size: 40px;
    margin: 0;
  }

  .filter-icon-container {
    flex-direction: row;
    margin-top: 0;
  }

  .filters-container {
    flex-direction: row;
    margin-top: 0;
  }

  .filter-select {
    width: 200px;
  }

  .viewAll-search-bar {
    margin-bottom: 0;
  }

}

@media (max-width: 1500px) {
  .view-all-games-container .casino-button {
    max-width: 100%;
  }

  .viewAll-search-bar input {
    width: 100%;
  }

  .filter-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }

  .view-all-games-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  .filters-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;
    margin-top: 10px;
    width: 100%;
    align-items: center;
  }

  .filters-container>*:nth-child(2n-1):nth-last-of-type(1) {
    grid-column: span 2;
  }

  .full-width {
    grid-column: span 2;
  }

}