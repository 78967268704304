.proceed-button {
    background: linear-gradient(to bottom, #E5AF05, #7F6103);
    color: white;
    font-size: 16px;
    width: 90%;
    font-family: var(--main-font-family);
    font-weight: 600;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    transition: background 0.3s ease, box-shadow 0.3s ease; /* Transition for background and box-shadow */
    margin: 10px auto; /* Center the button horizontally and add bottom margin */
  }
  
  .proceed-button:hover {
    background: linear-gradient(to bottom, #e2b729, #ac8920);
    box-shadow: 0 0 6px 1px gold;
  }
  
  .arrow {
    margin-left: 10px;
  }
  
  .loading-spinner {
    width: 24px;
    height: 24px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-left-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }