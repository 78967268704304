.sub-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
}

.title-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  font-size: 40px;
  background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--main-font-family);
  font-weight: 800;
  text-align: center;
}

.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media (max-width: 900px) {
  .sub-header.mobile {
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0px;
  }

  .sub-header.mobile .title {
    font-size: 32px;
    text-align: center;
    width: 100%;
  }

  .search-bar-container {
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  }
}