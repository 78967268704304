.popup-desktop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.form-choose-payment {
    padding-top: 20px;
}

.popup-content {
    background: #fff;
    border-radius: 25px;
    position: relative;
    width: 80%;
    max-width: 700px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
}

.popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background: #08236e;
    padding-top: 25px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    text-align: center;
}

.popup-header h1 {
    margin: 0;
    font-family: var(--main-font-family);
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    flex-grow: 1;
}

.close {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
    font-size: 20px;
}

.close img {
    width: 100%;
    height: 100%;
}

.close:hover {
    transform: scale(1.05);
}

.popup-form {
    padding: 20px 35px;
}

.popup-form h2 {
    text-align: left;
}

.popup-form p {
    text-align: left;
}

.button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 100%;
}

.grid-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 0px;
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border: 1px solid #036db1;
    border-radius: 50px;
    text-align: center;
    position: relative;
    user-select: none;
    display: flex;
    flex-direction: row;
    transition: background-color 0.3s ease;
}

.grid-button:hover {
    background-color: #036db1;
    color: #fff;
}

.grid-button input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.grid-button input[type="radio"]:checked+.label-content {
    background-color: #036db1;
    color: #fff;
}

.label-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border-radius: 50px;
    transition: background-color 0.3s ease;
}

.label-content .text {
    padding-left: 30px;
    text-align: center;
}

.icon {
    position: absolute;
    left: 0;
    margin-left: 10px;
    width: 35px;
}

.popupPaymentOptions {
    font-family: var(--main-font-family);
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.bank-option-container {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.bank-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 0px;
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border: 1px solid rgb(224, 172, 0);
    /* Change border color to gold */
    border-radius: 50px;
    text-align: center;
    position: relative;
    user-select: none;
    transition: background-color 0.3s ease;
}

.bank-button:hover {
    background-color: rgb(224, 172, 0);
    color: #fff;
}

.bank-button input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.bank-button input[type="radio"]:checked+.label-content {
    background-color: rgb(224, 172, 0);
    color: #fff;
}

.crypto-options-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 100%;
}


.subText {
    font-family: var(--main-font-family);
    font-size: 12px;
    font-weight: 400;
    color: #848484;
}

.amountHeader {
    padding-top: 10px;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.amountSubtext {
    font-family: var(--main-font-family);
    font-size: 12px;
    font-weight: 400;
    color: #848484;
}

.minimumAmount {
    font-family: var(--main-font-family);
    font-size: 12px;
    font-weight: 400;
    color: #848484;
    text-align: right !important;
}

.amountInput {
    width: 100%;
    height: 40px;
    font-size: 16px;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #036db1;
    border-radius: 4px;
    outline: none;
    font-family: var(--main-font-family);
    font-weight: 600;
    color: #000000;
    text-align: center;
}

.amountInput::-webkit-outer-spin-button,
.amountInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.amountInput[type="number"] {
    -moz-appearance: textfield;
}

.depositAmount {
    padding-top: 10px;
    font-family: var(--main-font-family);
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 2px;
    color: #036db1;
}

.depositAmountOf {
    padding: 10px;
    font-family: var(--main-font-family);
    font-size: 16px;
    font-weight: 400;
    color: #000;
}

.depositAmountSubtext {
    font-family: var(--main-font-family);
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.copy-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center items horizontally */
    width: 100%;
}

.errorMessage {
    color: red;
    margin-top: 5px;
    font-family: var(--main-font-family);
    font-size: 14px;
}

.disclaimer {
    font-family: var(--main-font-family);
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    padding-top: 0px;
    margin-bottom: 20px;
    /* Add spacing beneath the element */
}

.popup-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.disclaimer-button {
    background-color: transparent;
    font-family: var(--main-font-family);
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    padding-top: 0px;
    text-decoration: underline;
    border: none;
}

.disclaimer-button:hover {
    background-color: transparent;
}

.disclaimer-visible {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    height: 125px;
    overflow: auto;
    scrollbar-color: #036db1 transparent;
    scrollbar-width: thin;
    text-align: left;
}

.disclaimer-not-visible {
    display: none;
}

.header-selection {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.header-selection h1 {
    cursor: pointer;
    margin: 0;
    padding: 10px 20px;
    border: var(--main-font-color) 2px solid;
    border-radius: 10px;
}

.header-selection h1:hover {
    border: #fff 2px solid;
    border-radius: 10px;
    background-color: var(--main-font-color);
    transition: all 0.2s linear;

}

.header-selection h1.active {
    background-color: var(--main-font-color);
    border: #fff 2px solid;
    border-radius: 10px;
}

/* WITHDRAWAL */

.withdrawal-page-content-container-div {
    /* padding-left: 100px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.token-balance-container-div {
    text-align: left;
    margin: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.token-balance-container-div h3,
.token-balance-container-div span {
    color: var(--main-font-color);
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 24px;
}

.token-balance-container-div h3 {
    width: 70%;
    line-height: 1;
}

.token-balance-container-div span {
    width: 85%;
}

.withdrawal-page-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.withdrawal-page-form-item {
    width: 100%;
    display: flex;
    align-items: center;
}

.withdrawal-page-form label {
    font-family: var(--main-font-family);
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: left;
    width: 35%;
}

.withdrawLabelFont {
    font-weight: 600;
}

.withdrawalFeeFont {
    font-weight: 400;
}

.withdrawal-page-form input {
    border: 1px solid black;
    font-family: var(--main-font-family);
    font-weight: 400;
    font-size: 18px;
    width: 700px;
    height: 41px;
    background-color: transparent;
    margin-left: auto;
    padding: 0 16px;
}

.withdrawal-page-form input::-webkit-outer-spin-button,
.withdrawal-page-form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.withdrawal-page-form input[type="number"] {
    -moz-appearance: textfield;
}

.withdrawal-page-form-item span {
    font-size: 0.7em;
    position: absolute;
    width: max-content;
    left: 0;
    bottom: -21px;
}

.withdrawal-page-form-item:nth-child(2) label {
    position: relative;
}


.message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.withdrawal-error {
    font-family: var(--main-font-family);
    font-weight: 600;
    color: red;
}

.withdrawal-processing {
    font-family: var(--main-font-family);
    font-weight: 600;
    color: var(--main-font-color);
}

.withdrawal-success {
    font-family: var(--main-font-family);
    font-weight: 600;
    color: green;
}

.withdrawal-page-form button:hover {
    background: linear-gradient(to bottom, #e2b729, #ac8920);
    box-shadow: 0 0 6px 1px gold;
}

.withdrawalDisclaimer {
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
}

.btcSubtext {
    font-size: 0.7em;
    display: flex;
    font-weight: 400;
}

.btcSubtext a {
    display: flex;
    font-weight: 400;
    color: black;
}

.withdrawalMinimumAmountText {
    font-weight: 600;
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
}

.text-center{
    text-align: center;
}

.wallet-connect-button{
    background-color: #00e8a3;
    padding: 10px 10px;
    border: #00e8a3;
    font-weight: 600;
    border-radius: 8px;
}

.wallet-connect-icon{
    width: 18px;
    margin-right: 8px;
}

.wallet-connect-icon-2{
    width: 18px;
    margin-left: 8px;
}

.wallet-connected-icon{
    width: 12px;
    margin-left: 2px;
    margin-top: -8px;
}

.walletBalance{
    padding-top: 10px;
    font-family: var(--main-font-family);
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

  .form-wallet-connected{
    padding-top: 3px;
  }

  .walletConnectText{
    height: 70px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    font-size: 14px;
  }

  .etheriumAmount{
    float: right;
    font-size: 12px;
  }

  .etheriumIcon{
    width: 26px;
    margin-left: 2px;
  }

  .input-container-box {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .input-container-box-icon {
    position: absolute;
    right: 10px; /* Adjust this value to control the spacing from the right edge */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* This makes sure the icon doesn't block input interactions */
  }

  .popup-form-wallet-connected{
    padding: 20px 35px 0px 35px;
  }

  .popup-form-wallet-connected p {
    text-align: left;
}

.popup-form-wallet-connected h2 {
    text-align: left;
}

.infoIconDesktop{
    width: 50px;
    margin-top: -5px;
}

  .tooltip-container {
    position: relative;
    display: inline-block;
    color: rgb(0 232 163);
  }
  
  .tooltip-text {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%; /* Position above the tooltip trigger */
    left: 50%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap; /* Prevent line breaks */
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .button-container-wallet-connect{
    display: inline-flex;
    grid-gap: 106px;
    width: 100%;
  }

  .grid-button-wallet-connect {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22%;
    height: 40px;
    padding: 0px;
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border: 1px solid #036db1;
    border-radius: 5px;
    text-align: center;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: row;
    transition: background-color 0.3s ease;
}

.grid-button-wallet-connect:hover {
    background-color: #036db1;
    color: #fff;
}

.grid-button-wallet-connect input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.grid-button-wallet-connect input[type="radio"]:checked+.label-content-wallet-connect {
    background-color: #036db1;
    color: #fff;
}

.label-content-wallet-connect {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.label-content-wallet-connect .text {
    padding-left: 30px;
    text-align: center;
}

.page-3-wallet-connect{
    max-height: 550px;
    overflow: auto;
}

.wallet-connect-currency-icon {
    position: absolute;
    left: 0;
    margin-left: 10px;
    width: 25px;
}