/* LOGGED OUT BUTTONS */
.buyTokenBlueButton {
  border-radius: 4px;
  background-image: linear-gradient(180deg,
      rgba(81, 133, 233, 1),
      rgba(16, 48, 109, 1));
  color: white;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 600;
  min-width: 120px;
  font-size: 16px;
  padding: 11px 20px;
  border: none;
  border-radius: 4px;
  transition: all 0.5s;
  cursor: pointer;
}

.buyTokenBlueButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.buyTokenBlueButton span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -10px;
  transition: 0.5s;
}

.buyTokenBlueButton:hover span {
  padding-right: 15px;
}

.buyTokenBlueButton:hover span:after {
  opacity: 1;
  right: 0;
}

.loggedOutYellowButton {
  border-radius: 4px;
  background-image: linear-gradient(180deg,
      rgba(248, 190, 0, 1),
      rgba(125, 91, 26, 1));
  color: white;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 11px 20px;
  min-width: 120px;
  border: none;
  border-radius: 4px;
  transition: all 0.5s;
  cursor: pointer;
}

.loggedOutYellowButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.loggedOutYellowButton span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -10px;
  transition: 0.5s;
}

.loggedOutYellowButton:hover span {
  padding-right: 15px;
}

.loggedOutYellowButton:hover span:after {
  opacity: 1;
  right: 0;
}

/* LOGGED IN BUTTONS */

.depositCashYellowButton {
  border-radius: 4px;
  background-image: linear-gradient(180deg,
      rgba(248, 190, 0, 1),
      rgba(125, 91, 26, 1));
  color: white;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 11px 20px;
  border: none;
  min-width: 120px;
  border-radius: 4px;
  transition: all 0.5s;
  cursor: pointer;
}

.depositCashYellowButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.depositCashYellowButton span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -10px;
  transition: 0.5s;
}

.depositCashYellowButton:hover span {
  padding-right: 15px;
}

.depositCashYellowButton:hover span:after {
  opacity: 1;
  right: 0;
}

.currentTokens {
  border-radius: 4px;
  background-image: linear-gradient(180deg,
      rgba(248, 190, 0, 1),
      rgba(125, 91, 26, 1));
  color: white;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  border-radius: 4px;
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
  margin-left: 20px;
  width: 120px;
}

.currentTokens small {
  display: block;
  font-weight: 300;
  font-size: 8px;
  position: relative;
  top: -7px;
}

.currentTokens span {
  padding-bottom: 10px;
  position: relative;
  /* Add this */
  top: -5px;
  /* Adjust this value as needed */
}

.svgIcon {
  display: block;
  position: absolute;
  left: -20px;
  z-index: 1;
  transform: translateY(-50%);
  top: 50%;
}

@media (max-width: 1100px) {
  .depositCashYellowButton {
    font-size: 14px;
  }
}