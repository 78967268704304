.sports .coming-soon h1 {
    font-size: 40px;
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
    text-align: center;
}

.sports .coming-soon {
    padding-top: 8vh;
}

/* Disables search bar, remove this styling when sports page is ready to use */
.sports-subheader .search-button,
.sports-subheader .search-bar {
    display: none;
}

/* Disables category buttons, remove this styling when sports page is ready to use */
.sports-categories .category-section .category-button-container {
    background: unset;
    cursor: not-allowed;
    filter: grayscale(1);
}

.sports-categories .category-section .category-button-container::before {
    transition: none;
    opacity: unset;
}

.sports-categories .category-section .category-button-container:hover {
    box-shadow: unset;
}

.sports-categories .category-section .category-button-container:hover span {
    font-weight: 300;
}

.sports-categories .category-button {
    cursor: not-allowed !important;
    filter: grayscale(1);
}