/* dropdownButton.css */

.dropdown {
    position: relative;
    display: inline-block;
    margin-left: 15px;
}

.dropdown-mobile {
    position: relative;
    display: inline-block;
}

.dropdownButton {
    border-radius: 4px;
    background-image: linear-gradient(180deg, rgba(248, 190, 0, 1), rgba(125, 91, 26, 1));
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
}

.dropdownButton .svgIcon {
    display: block;
    margin-left: 2px;
    transform: translateY(-50%);
    top: 50%;
    width: 33px;
    height: 33px;
}

.dropdownButton .arrowIcon {
    margin-left: 10px;
    fill: white;
}

/* Default dropdown content styling for desktop */
.dropdownContent {
    display: block;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 0;
    top: 100%;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.6s ease, transform 0.6s ease, visibility 0.6s;
}

.dropdown:hover .dropdownContent {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

/* Override for mobile dropdown content */
.dropdownContent.open {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translate(-15px, 2px);
    z-index: 2;
}

.balanceItem {
    color: black;
    padding: 10px 20px;
    font-size: 13px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    text-align: left;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.balanceItem:hover {
    background-color: var(--main-font-color);
    border-radius: 4px;
    color: white;
}

.balanceItem span {
    display: block;
    font-size: 16px;
}

.balanceItem small {
    display: block;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    font-size: 12px;
    margin-top: -5px;
}

.user-icon-button img {
    height: 50px;
    width: 50px;
}