.activity-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    backdrop-filter: blur(10px);
}

.activity-header {
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 20px;
    align-self: flex-start;
    /* Align header to the left */
}

.activity-table-container {
    width: 100%;
    /* Adjust as needed */
    background-color: rgba(0, 0, 0, 0.3);
    /* Slightly darker background for the table */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dtable-account-settings-loading {
    color: white;
    font-weight: 600;
}