/* EventButton.css */
.event-button {
  width: 230px;
  height: 120px;
  color: white;
  font-size: 16px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 300;
  text-align: center;
  border: none;
  border-radius: 10px;
  background: linear-gradient(180deg, #101c75cc 0%, #073e76cc 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  /* Prevent shrinking */
  z-index: 0;
}



.event-button .offline-icon {
  filter: none !important;
  /* Override parent filter */
  cursor: default !important;
  /* Override parent cursor */
  opacity: 0.8;
}

.event-button:hover .offline-icon {
  opacity: 1;
  box-shadow: 0 0 10px red;
  text-shadow: 0 0 5px white;
}

.event-button:hover .offline-icon::before {
  box-shadow: 0 0 5px rgb(255, 138, 138);
}

.event-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--button-bg-image);
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

.event-button:hover::before {
  opacity: 1;
}

.event-button:hover {
  box-shadow: 0 0 6px 1px gold;
}

.event-button span {
  position: relative;
  z-index: 0;
  transition: font-weight 0.3s ease-in-out, color 0.3s;
}

.event-button:hover span {
  font-weight: 600;
  text-shadow: rgb(0, 0, 0) 2px 0 10px;
}

.live-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  background-color: green;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 5px;
  z-index: 0;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    text-shadow 0.3s ease-in-out;
}

.live-icon::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(138, 255, 138);
  border-radius: 50%;
  margin-right: 5px;
}

.event-button:hover .live-icon {
  opacity: 1;
  box-shadow: 0 0 10px green;
  text-shadow: 0 0 5px white;
}

.event-button:hover .live-icon::before {
  box-shadow: 0 0 5px rgb(138, 255, 138);
}

@media (max-width: 600px) {

  /* The above breakpoint should be whatever responsive.mobileSmall.breakpoint.max is in EventSection.jsx  */
  .event-button {
    width: 95%;
    height: unset;
    aspect-ratio: 23/12;
  }
}

.offline-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 5px;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    text-shadow 0.3s ease-in-out;
}

.offline-icon::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(255, 138, 138);
  border-radius: 50%;
  margin-right: 5px;
}

.event-button:hover .offline-icon {
  opacity: 1;
  box-shadow: 0 0 10px red;
  text-shadow: 0 0 5px white;
}

.event-button:hover .offline-icon::before {
  box-shadow: 0 0 5px rgb(255, 138, 138);
}