.user-settings-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    backdrop-filter: blur(10px);
}

.user-settings-header {
    text-align: left;
    background: -webkit-linear-gradient(90deg, #815F1A, #F2B902);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--main-font-family);
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 20px;
}

.user-settings-sub-header {
    text-align: left;
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 18px;
    color: var(--main-font-color);
    margin-bottom: 20px;
}

.user-settings-form-group {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.user-settings-form-group label {
    margin-bottom: 8px;
    font-size: 14px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 600;
    color: white;
    text-align: left;
}

.user-settings-language-selector {
    width: 100%;
}

.user-settings-password-input-container {
    display: flex;
    align-items: center;
    position: relative;
}

.user-settings-password-input {
    width: 100%;
    padding: 8px;
    padding-right: 30px;
    /* Adjust padding to accommodate icon inside input */
    border-radius: 4px;
    border: 1px solid var(--main-font-color);
    background-color: #172644;
    color: white;
}

.user-settings-password-toggle-icon {
    position: absolute;
    right: 10px;
    /* Position icon inside input */
    cursor: pointer;
    width: 18px;
    height: 18px;
}