/* .category-button {
  width: 90%;
  height: 75px;
  color: white;
  font-size: 16px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 300;
  text-align: center;
  border: none;
  border-radius: 10px;
  background: linear-gradient(180deg, #101C75CC 0%, #073E76CC 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
} */

.category-button {
  width: 300px;
  height: 75px;
  color: white;
  font-size: 16px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 300;
  text-align: center;
  border: none;
  border-radius: 10px;
  background: linear-gradient(180deg, #101c75cc 0%, #073e76cc 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  /* Prevent shrinking */
  z-index: 0;
  text-decoration: none;
}

.category-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--button-bg-image);
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}

.category-button:hover::before {
  opacity: 0.8;
}

.category-button:hover {
  box-shadow: 0 0 6px 1px gold;
}

.category-button span {
  position: relative;
  z-index: 0;
  transition: font-weight 0.3s ease-in-out, color 0.3s;
}

.category-button:hover span {
  font-weight: 600;
  text-shadow: rgb(0, 0, 0) 2px 0 10x;
}

@media (max-width: 670px) {

  /* The above breakpoint should be whatever responsive.tiny.breakpoint.max is in CategorySection.jsx  */
  .category-button {
    aspect-ratio: 175/75;
    width: 95% !important;

  }

}