.partners-section {
  padding-top: 0px;
  padding-bottom: 10px;
  padding-right: 20px;
}

.partners-list-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-left: 10px;
  padding-top: 10px;
  margin-right: 20px;
}

.partners-list-wrapper .react-multi-carousel-list {
  padding: 5px;
}

.partners-list {
  display: flex;
  width: fit-content;
}

.partner-button {
  width: 180px;
  height: 80px;
  color: white;
  font-size: 16px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 300;
  text-align: center;
  border-radius: 5px;
  background: #172644;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
}

.partner-button-image {
  max-width: 70%;
  max-height: 70%;
}