@import url(https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap);

.homepage-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    width: 100%;
}

.homepage-logo img {
    width: 600px;
    padding: 30px;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.hero-center .headerBelowLogo {
    color: var(--main-font-color);
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 32px;
}

.hero-center h1 {
    color: var(--primary-text-color);
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 64px;
    margin: 0;
}

.hero-center h2 {
    color: var(--main-font-color);
    font-family: var(--main-font-family);
    font-weight: 600;
    font-size: 32px;
}


.hero-background {
    width: 100%;
    justify-content: space-between;
    position: relative;
    display: flex;
}

.hero-center {
    position: absolute;
}

.hero-image-left img,
.hero-image-right img {
    position: relative;
}

.hero-image-left img {
    margin-left: -20px;
}

.hero-image-right img {
    margin-right: -20px;
}

.hero-image-right {
    display: flex;
    justify-content: flex-end;
}

.hero-image-left {
    display: flex;
    justify-content: flex-start;
}

/* Mobile CSS */

.homepage-mobile .homepage-hero {
    height: 30vh;
}

.homepage-mobile .homepage-logo img {
    width: 240px;
    padding: 10px;
}

.homepage-mobile .hero-center h1 {
    font-size: 30px;
}

.homepage-mobile .hero-center h2 {
    font-size: 10px;
}

.homepage-mobile .hero-image-left img {
    right: 40px;
}

.homepage-mobile .hero-image-right img {
    left: 40px;
}

.homepage-mobile .hero-image-left,
.homepage-mobile .hero-image-right {
    width: 150px;
}

.homepage-mobile .hero-image-left img,
.homepage-mobile .hero-image-right img {
    width: 200px;
}