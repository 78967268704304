.sub-banner-card {
  width: 94%;
  /* height: 40vh; */
  color: white;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 300;
  text-align: center;
  border: none;
  border-radius: 10px;
  background: linear-gradient(180deg, #101c75cc 0%, #073e76cc 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  margin: 10px;
  aspect-ratio: 1358 / 800;
}

.sub-banner-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--button-bg-image);
  background-size: cover;
  background-position: center;
}

.sub-banner-card:hover {
  transform: scale(1.03);
  text-shadow: #fc0 1px 0 10px;
  /* large blur */
  /* box-shadow: 0 0 40px rgba(52, 152, 219, 0.7); */
  box-shadow: 0 0 10px rgba(255, 204, 0, 0.9);
}

.sub-banner-card span {
  color: #e3ad05;
  font-family: var(--main-font-family);
  font-weight: 400;
  font-size: 28px;
  letter-spacing: 5px;
  background: linear-gradient(360deg, #08196a 0%, #064c7a 100%);
  border-radius: 40px;
  width: 85%;
  height: 14%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

@media (max-width: 980px) {
  .sub-banner-card span {
    font-size: 24px;
  }
}

@media (max-width: 750px) {
  .sub-banner-card span {
    font-size: 14px;
  }

  .sub-banner-card {
    width: 100%;
    height: 35vh;
    /* height: 40vh; */
    color: white;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 300;
    text-align: center;
    border: none;
    border-radius: 10px;
    background: linear-gradient(180deg, #101c75cc 0%, #073e76cc 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    aspect-ratio: 1600 / 1233;
  }
}

@media (max-width: 550px) {
  .sub-banner-card span {
    font-size: 10px;
  }
}

@media (max-width: 400px) {
  .sub-banner-card span {
    font-size: 8px;
  }
}