.input-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--main-font-color);
  background-color: #172644;

  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  width: 70%;
  position: relative;
}

.text-input {
  border: none;
  outline: none;
  font-family: var(--main-font-family);
  font-size: 16px;
  color: white;
  background-color: transparent;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 40px;
  /* Space to accommodate the icon */
}

.copy-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 10px;
  /* Spacing to the left of the copy icon */
  position: absolute;
  right: 10px;
  /* Position it inside the input container */
}

.input-container::after {
  content: "";
  position: absolute;
  right: 40px;
  /* Adjust to fit the copy icon */
  width: 20px;
  /* Width of the fade effect */
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255, 0.1));
}

.copyText {
  padding-top: 10px;
  font-family: var(--main-font-family);
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

.qr-code-container {
  background-color: white;
  border-radius: 20px;
  padding: 6px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

}