.view-all-button {
    background-color: #4a90e2; /* Adjust the color as needed */
    color: white;
    font-size: 14px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-left: auto;
  }
  
  .view-all-button:hover {
    background-color: #357abd; /* Adjust the hover color as needed */
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
    text-shadow: 0 0 5px gold;
  }
  