.footer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(90deg, #026098, #07107D);
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - 100px);
    padding: 5px;

}

.footerLogo {
    width: 120px;
}

.footer-content {
    align-items: center;
    padding: 0 10%;
}

.footer-links {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
}

.footer-links h4 a,
.footer-links h4 {
    padding-left: 10px;
    color: var(--primary-text-color);
    font-family: var(--main-font-family);
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
}

.footer-links a:hover {
    filter: brightness(0.7)
}

.footer-logo {
    align-items: center;
    padding: 5px;
}

.footer-subtext h4 {
    color: var(--primary-text-color);
    font-family: var(--main-font-family);
    font-size: 12px;
}

.footer-disclaimer {
    color: var(--primary-text-color);
    font-family: var(--main-font-family);
    font-size: 13px;
}

.termsAndConditions a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;
}

.termsAndConditions a:hover {
    color: gold;
}

/* MOBILE STYLE */

.mobile-footer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(90deg, #026098, #07107D);
    width: calc(100%);
    padding: 5px;
    z-index: 2;
}

.mobile-footer .footer-logo {
    align-items: center;
    padding-bottom: 0;
}

.mobile-footer .footer-content {
    padding: 0;
    align-items: center;
    /* Ensure content is centered */
}

.mobile-footer .footer-links {
    padding-bottom: 5px;
}

.mobile-footer .footer-links h4 a,
.mobile-footer .footer-links h4 {
    padding: 0 5px;
    font-size: 12px;
    /* Smaller font size */
    margin: 2px 0;
    /* Reduce margins */
}

.mobile-footer-disclaimer {
    color: var(--primary-text-color);
    font-family: var(--main-font-family);
    font-size: 11px;
}

.mobile-footer .footer-subtext h4 {
    font-size: 10px;
    /* Smaller font size */
    margin: 2px 0;
    /* Reduce margins */
}