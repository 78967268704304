.bank-payment-popup-offers-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.proceed-loading-spinner {
  width: 25px;
  height: 25px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-left-color: #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.bank-payment-popup-offers li {
  font-size: 18px;
  line-height: 1.6;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.bank-payment-popup-offers {
  background: white;
  width: 80%;
  max-width: 600px;
  border-radius: 25px;
  height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.bankPopupOffersHeader {
  margin: 0;
  font-family: var(--main-font-family);
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  flex-grow: 1;
  padding-bottom: 10px;
}

.bank-popup-offers-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: #fff;
  padding: 20px;
  position: relative;
  text-align: center;
}
.provider-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.provider-item {
  padding: 12px;
  margin-bottom: 8px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.provider-selected {
  background: #08236e;
  color: #f9f9f9 !important;
}

.provider-selected p {
  color: #f9f9f9 !important;
}

.provider-item:hover {
  opacity: 0.8;
}

.img-wrapper {
  width: 200px;
  height: 40px;
  background: #f9f9f9;
  border-radius: 4px;
}

.img-wrapper img {
  width: 200px;
  height: 40px;
  object-fit: contain;
}

.provider-item p {
  margin: 4px 0;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}
.popup-content-offers {
  padding: 16px;
  overflow-y: auto;
}
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}
@media (max-width: 768px) {
  .bank-payment-popup-offers {
    background: white;
    width: 90%;
    border-radius: 25px;
    height: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}
