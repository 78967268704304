.alert-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.alert-dialog-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.alert-dialog-content p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.4;
}

.alert-dialog-content button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.alert-dialog-content button:hover {
  background: #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .alert-dialog-content {
    width: 90%;
    padding: 15px;
  }

  .alert-dialog-content p {
    font-size: 14px;
  }

  .alert-dialog-content button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
