.category-section {
  display: flex;
  gap: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 40px;
}

.category-section-wrapper {
  overflow: hidden;
  position: relative;
  transition: height 0.5s ease-in-out;
}

.category-section-wrapper .react-multi-carousel-list {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.carousel-view {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  overflow: hidden;
  scroll-behavior: smooth;
  height: 160px;
}

.category-grid-view {
  display: grid !important;
  gap: 10px;
  padding: 10px 20px 40px 20px;
  overflow: hidden;
}

.category-header {
  display: flex;
  padding-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  font-size: 20px;
  font-family: var(--main-font-family), sans-serif;
  font-weight: 400;
  color: var(--main-font-color);
}

.homeCategoryIcon {
  padding-right: 10px;
  height: 30px;
  width: 30px;
}

@media (max-width: 768px) {
  .category-grid-view {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 10px;
    padding: 10px 0px 40px 0px;
    overflow: hidden;
  }

  .category-header {
    display: flex;
    padding-bottom: 0px;
    padding-right: 10px;
    padding-left: 0px;
    padding-top: 10px;
    font-size: 20px;
    font-family: var(--main-font-family), sans-serif;
    font-weight: 400;
    color: var(--main-font-color);
  }

  .homeCategoryIcon {
    margin-right: 0px !important;
    padding-right: 10px;
    height: 30px;
    width: 30px;
  }

  .category-section-wrapper .react-multi-carousel-list {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}